// MAIN.JS
jQuery(function($) {
	
	$(document).ready(function() {
		setTimeout(function() {
			$('#hideMe').fadeOut(1000);
		}, 3000);
	});
	
	/**********************************************/
	
	$('.layerslider').layerSlider({
		hoverPrevNext: false,
		navPrevNext: true,
		navStartStop: false,
		pauseOnHover: false,
		showCircleTimer: false
	});
	
	/**********************************************/
	
	// MENU
	var menu = ".main-menu";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});
	
	$("[hassub]").click(function() {
		var submenu = $(this).parent().find(".submenu");
		$(submenu).addClass("activated");
	});
	
	$("[retour]").click(function() {
		var submenu = $(this).parent();
		$(submenu).removeClass("activated");
	});
	
	/**********************************************/
	
	// Menu On Scroll
	$(document).ready(function() {
		$(window).scroll();
	});
	$(window).scroll(function(event) {
		var scrollTop = $(window).scrollTop();
		if (scrollTop > 0) {
			$("body").addClass("scrolled");
		}
		else {
			$("body").removeClass("scrolled");
		}
	});
	
	/**********************************************/

	$(window).scroll(function() {
		if ($(window).scrollTop() > 100) {
			$(".module-menu").addClass('scrolled');
		} else {
			$(".module-menu").removeClass('scrolled');
		}
	}).scroll();

	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				var self = this;
				$(self).addClass("in");
				$(self).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			/*$.each($all_disappeared_elements, function(e) {
				var self = this;
				$(self).removeClass("in");
				$(self).css("opacity", 0);
			});*/
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}
	
	/**********************************************/

	// SMOOTH SCROLL ANCHOR
	$('.smooth-scroll').click(function(e) {
		e.preventDefault();
		$target = $($(this).attr('href'));
		$navHeight = $(".module-menu").outerHeight();
		$targetTopY = $target.offset().top - $navHeight;
		
		/* smooth scroll */
		TweenMax.to($(window), 1, {
			scrollTo: {
				y: $targetTopY,
				autoKill: true
			},
			ease: "Expo.easeInOut"
		});
	});

	/**********************************************/
	
	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='text'].phone").mask("(999) 999-9999");
		$("input[type='text'].postalcode").mask("a9a9a9");
	}
	
	/**********************************************/
	
	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});
		$('.image-group-popup').magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery: {
		    enabled: true
		  }
		});
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').magnificPopup({
			delegate: 'a',
			type: 'iframe'
		});
	}
	
	/**********************************************/
	
	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}
	
	/**********************************************/
	
	// OWL.CAROUSEL2
	if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.owl-carousel').owlCarousel({
			autoplay:true,
			autoplayTimeout: 3000,
			items: 1,
			loop:true,
			nav:false,
			responsiveClass:true
		})
	}
	/**********************************************/
	
	// PARALLAX
	if ($.fn.parallax) {
		$(".parallax-slow").parallax({
			speed: 0.1
		});
		$(".parallax").parallax({
			speed: 0.3
		});
		$(".parallax-fast").parallax({
			speed: 0.5
		});
	}
	
});
